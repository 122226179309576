import {
  EntityState,
  EntityStore,
  getEntityType,
  QueryEntity,
  SelectOptions,
} from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

export class QueryEntityExtended<T extends EntityState> extends QueryEntity<T> {
  constructor(protected store: EntityStore<T>) {
    super(store);
  }

  selectActiveIdAfterLoading() {
    return this.selectLoading().pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.selectActiveId()),
    );
  }

  selectEntityAfterLoading(entityId: string) {
    return this.selectLoading().pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.selectEntity(entityId as any)),
    );
  }

  selectAllNullLoading(options?: SelectOptions<getEntityType<T>>) {
    return this.nullLoading(() =>
      options ? this.selectAll(options) : this.selectAll(),
    );
  }

  selectActiveNullLoading(): Observable<getEntityType<T> | null> {
    return this.nullLoading(
      () => this.selectActive() as Observable<getEntityType<T>>,
    );
  }

  selectEntityNullLoading(id: string) {
    return this.nullLoading(() => this.selectEntity(id as any));
  }

  protected nullLoading<K>(fct: () => Observable<K>): Observable<K | null> {
    return this.selectLoading().pipe(switchMap((l) => (l ? of(null) : fct())));
  }
}

export class QueryEntityCustom<
  T extends EntityState,
> extends QueryEntityExtended<T> {
  constructor(
    protected store: EntityStore<T>,
    protected routerQuery: RouterQuery,
  ) {
    super(store);
  }

  selectRouterEntity(name: string) {
    return this.routerQuery.selectParams<string>(name).pipe(
      filter((id) => !!id),
      switchMap((id) => this.selectEntityNullLoading(id)),
    );
  }
}
